<template>
  <div>
    <button
      id="scrollToTopButton"
      class="scrollToTopButton hidden"
      @click="goToTop()"
    >
      <IconSax type="outline" name="arrow-up-3" />
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    goToTop() {
      const layout = document.querySelector(".layout-page__overflow");

      if (layout) {
        layout.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    handleScroll() {
      const scroll = document.querySelector("#scrollToTopButton");
      const layout = document.querySelector(".layout-page__overflow");

      if (layout && layout.scrollTop > 30) {
        scroll && scroll.classList.remove("hidden");
      } else {
        scroll && scroll.classList.add("hidden");
      }
    },
  },
  data() {
    return {
      elementoVisivel: false,
    };
  },
  mounted() {
    const layout = document.querySelector(".layout-page__overflow");
    layout.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    const layout = document.querySelector(".layout-page__overflow");

    if (layout) {
      document
        .querySelector(".layout-page__overflow")
        .removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>

<style lang="scss" scoped>
.scrollToTopButton {
  border: none;
  border-radius: 4px;
  background: var(--my-courses-background-color);
  z-index: 10;
  display: flex;
  width: 36px;
  height: 36px;
  position: fixed;
  bottom: 10px;
  right: 26px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  ::v-deep svg path {
    fill: var(--maincolor);
  }
}

@media screen and (max-width: 500px) {
  .scrollToTopButton {
    border-radius: 50px;
    width: 65px;
    height: 65px;
    bottom: 20px;
    right: 100px;
  }
}

.hidden {
  display: none;
}
</style>
